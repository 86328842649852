<!-- @format -->
<script lang="ts">
  import { valueGetters } from "~/components/fragment-sets/basic_component/util";
  import type { StoresKey } from "~/stores";
  import { getStoresContext } from "~/stores";

  export let request_store_key: StoresKey;
  export let fragment: Fragment;
  export let position: Position = "top-right";

  type Position = "top-left" | "top-right" | "bottom-right" | "bottom-left";
  const stores_context = getStoresContext(request_store_key);
  const { locale } = stores_context;

  const { getText } = valueGetters(stores_context);

  $: before = $locale && getText(position + "-before", fragment);
  $: after = $locale && getText(position + "-after", fragment);
</script>

<div class="wrap" class:right={position == "bottom-right" || position == "top-right"}>
  <div class="before-text">{before}</div>
  <div class="after-text">{after}</div>
</div>

<style lang="scss">
  :root {
    --top-after: 25px;
    --top-before: -25px;
  }

  @media (min-width: 992px) {
    :root {
      --top-after: 35px;
      --top-before: -35px;
    }
  }

  @media (min-width: 1600px) {
    :root {
      --top-after: 45px;
      --top-before: -45px;
    }
  }
  .right {
    display: flex;
    justify-content: end;
  }

  .wrap {
    position: relative;
    height: 45px;
    width: 100%;
    color: #fff;
    font-family: var(--font-items);
    @media screen and (min-width: 992px) {
      height: 65px;
    }
    @media screen and (min-width: 1600px) {
      height: 85px;
    }
  }
  .before-text,
  .after-text {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    font-size: 40px;
    animation: transformed-text-before 10s linear infinite;
    -webkit-animation: transformed-text-before 10s linear infinite;
    @media screen and (min-width: 992px) {
      font-size: 60px;
    }
    @media screen and (min-width: 1600px) {
      font-size: 80px;
    }
  }
  .after-text {
    animation: transformed-text-after 10s linear infinite;
    -webkit-animation: transformed-text-after 10s linear infinite;
    top: var(--top-after);
  }

  @keyframes transformed-text-before {
    0% {
      transform: rotateX(0deg) skewX(0);
      top: 0;
    }

    10% {
      transform: rotateX(90deg) skewX(-10deg);
      top: var(--top-before);
    }
    50% {
      transform: rotateX(90deg) skewX(-10deg);
      top: var(--top-before);
    }

    60% {
      transform: rotateX(0deg) skewX(0);
      top: 0;
    }
    100% {
      transform: rotateX(0deg) skewX(0);
      top: 0;
    }
  }

  @keyframes transformed-text-after {
    0% {
      transform: rotateX(90deg) skewX(10deg);
      top: var(--top-after);
    }
    10% {
      transform: rotateX(0deg) skewX(0);
      top: 0;
    }
    50% {
      transform: rotateX(0deg) skewX(0);
      top: 0;
    }
    60% {
      transform: rotateX(90deg) skewX(10deg);
      top: var(--top-after);
    }
    100% {
      transform: rotateX(90deg) skewX(10deg);
      top: var(--top-after);
    }
  }
</style>
