<!-- @format -->
<script lang="ts">
  export let pulseColor = "#000";
</script>

<div class="svg-container">
  <svg
    class="w-100 h-100"
    width="3614"
    height="487"
    viewBox="0 0 3614 487"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1452.16 223.266C1338.96 223.266 1331.27 22.1238 1210.66 22.1238C1142.65 22.1238 1118.48 81.2876 1118.48 81.2876C1118.48 81.2876 1094.3 22.1238 1026.29 22.1238C922.939 22.1238 864.417 223.266 804.634 223.266C751.125 223.266 736.661 138.617 662.991 138.617C591.267 138.617 588.428 210.821 542.165 210.821C484.863 210.821 426.82 0.689636 299.922 0.689636C159.395 0.689636 141.939 233.558 0 233.558V253.326C141.939 253.326 159.379 486.195 299.922 486.195C426.835 486.195 484.863 276.063 542.165 276.063C588.428 276.063 591.283 348.267 662.991 348.267C736.676 348.267 751.125 263.618 804.634 263.618C864.417 263.618 922.954 464.76 1026.29 464.76C1099.82 464.76 1118.48 405.597 1118.48 405.597C1118.48 405.597 1137.14 464.76 1210.66 464.76C1331.27 464.76 1338.96 263.618 1452.16 263.618C1594.1 263.618 1611.56 485.519 1752.09 485.519C1873.47 485.519 1903.91 352.627 1988.81 352.627C2040.59 352.627 2080.29 396.556 2113.4 457.933C2146.51 396.556 2183 346.902 2246.72 346.902C2331.92 346.902 2358.55 464.085 2456.38 464.085C2515.65 464.085 2541.87 404.831 2581.88 404.831C2621.89 404.831 2648.11 464.085 2706.97 464.085C2827.58 464.085 2813.1 293.794 2926.3 293.794C2989.12 293.794 3056.51 363.453 3089.63 430.348C3122.74 363.453 3175.78 293.794 3239.5 293.794C3303.22 293.794 3356.27 363.453 3389.38 430.348C3422.49 363.453 3500.82 253.326 3613.31 253.326V233.558C3500.82 233.558 3422.49 122.066 3389.38 55.171C3356.27 122.066 3303.22 191.724 3239.5 191.724C3175.78 191.724 3122.74 122.066 3089.63 55.171C3051.5 108.5 2989.12 191.724 2926.3 191.724C2813.1 191.724 2827.58 21.4342 2706.97 21.4342C2647.7 21.4342 2621.49 80.6876 2581.47 80.6876C2541.46 80.6876 2515.25 21.4342 2456.38 21.4342C2358.55 21.4342 2331.92 138.617 2246.72 138.617C2183 138.617 2146.51 88.963 2113.4 27.5855C2080.29 88.963 2040.59 132.892 1988.81 132.892C1903.91 132.892 1873.47 0 1752.09 0C1611.56 0 1594.1 223.266 1452.16 223.266Z"
      fill={pulseColor} />
  </svg>
</div>

<svg height="0" width="0">
  <defs>
    <clipPath id="type-3-small">
      <path
        d="M45.247 136.563C63.5805 136.563 79.3532 120.094 86.4629 96.4378C93.5727 120.094 109.345 136.563 127.679 136.563C152.666 136.563 172.926 105.99 172.926 68.2814C172.926 30.573 152.666 -3.22887e-05 127.679 -3.44732e-05C109.345 -3.6076e-05 93.5727 16.4688 86.463 40.1251C79.3532 16.4688 63.5805 -4.00768e-05 45.247 -4.16796e-05C20.2594 -4.38641e-05 8.55599e-05 30.573 8.22633e-05 68.2814C7.89667e-05 105.99 20.2594 136.563 45.247 136.563Z" />
    </clipPath>
    <clipPath id="type-1-small">
      <path
        d="M50.4249 137C78.2738 137 100.85 106.332 100.85 68.5C100.85 30.6685 78.2738 0 50.4249 0C22.576 0 0 30.6685 0 68.5C0 106.332 22.576 137 50.4249 137Z"
        fill="#B27357" />
    </clipPath>

    <clipPath id="type-3-medium">
      <path
        d="M64.8536 194.11C90.8284 194.11 113.175 170.777 123.248 137.261C133.321 170.777 155.668 194.11 181.642 194.11C217.045 194.11 245.748 150.794 245.748 97.3692C245.748 43.9441 217.045 0.628456 181.642 0.628453C155.668 0.628451 133.321 23.9613 123.248 57.4774C113.175 23.9613 90.8284 0.628445 64.8537 0.628443C29.4513 0.62844 0.748056 43.9441 0.748051 97.3691C0.748046 150.794 29.4513 194.11 64.8536 194.11Z"
        fill="#B27357" />
    </clipPath>
    <clipPath id="type-1-medium">
      <path
        d="M84.5 229.579C131.168 229.579 169 178.186 169 114.789C169 51.3929 131.168 0 84.5 0C37.832 0 0 51.3929 0 114.789C0 178.186 37.832 229.579 84.5 229.579Z"
        fill="#B27357" />
    </clipPath>

    <clipPath id="type-1-large">
      <path
        d="M138.09 374.099C214.117 374.099 275.749 290.375 275.749 187.096C275.749 83.8177 214.117 0.09375 138.09 0.09375C62.0635 0.09375 0.431641 83.8177 0.431641 187.096C0.431641 290.375 62.0635 374.099 138.09 374.099Z" />
    </clipPath>
    <clipPath id="type-3-large">
      <path
        d="M104.073 314.11C146.242 314.11 182.521 276.23 198.874 221.818C215.227 276.23 251.506 314.11 293.675 314.11C351.149 314.11 397.748 243.788 397.748 157.055C397.748 70.3214 351.149 5.69614e-05 293.675 5.19368e-05C251.506 4.82503e-05 215.227 37.8801 198.874 92.2922C182.521 37.8801 146.242 3.90478e-05 104.073 3.53612e-05C46.5984 3.03367e-05 -0.000253346 70.3214 -0.000260928 157.055C-0.000268511 243.788 46.5983 314.11 104.073 314.11Z" />
    </clipPath>
  </defs>
</svg>

<style lang="scss">
  .svg-container {
    width: 1571px;
    aspect-ratio: 3614/487;
    @media screen and (min-width: 992px) {
      width: 2379px;
    }
    @media screen and (min-width: 1600px) {
      width: 3614px;
    }
  }

</style>
